import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Content from '../components/Content';
import ProjectItem from '../components/ProjectItem';
import Title from '../components/Title';
import { BigImg } from './index';
import ImageBase from './ImageBase';

const TextImg = styled.div`
  padding: 1rem 0;
  background: ${props => props.theme.colors.lightBlue};
  overflow: hidden;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    flex-direction: column;
  }
`;

const Col2Img = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    width: 100%;
  }
  .imgLeft {
    height: 530px;
    width: auto;
    max-width: none;
    float: right;
    margin: 0 36px 0 0;
    @media (max-width: ${props => props.theme.breakpoint.m}) {
      margin: 0 -36px 2rem 0;
      height: 300px;
    }
  }
  .imgRight {
    width: 1500px;
    height: 530px;
    max-width: none;
    float: left;
    margin: 0 0 0 36px;
    @media (max-width: ${props => props.theme.breakpoint.m}) {
      margin: 2rem 0 0 -36px;
      height: 300px;
    }
  }
`;

const Col2Text = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    width: 100%;
  }
  align-items: center;
  display: flex;
`;

const Text = styled.div`
  padding: 10rem 0;
  &.bg {
    background: ${props => props.theme.colors.lightBlue};
  }
  &.noBottomPadding {
    padding: 10rem 0 0 0;
  }
`;

const ContentText = styled(Content)`
  padding: 0 20rem;
`;

const CardsGrid = styled.div`
  padding: 1rem 0;
  background: ${props => props.theme.colors.lightBlue};
`;

const Masonry = styled.div`
  column-count: 2;
  column-gap: 1rem;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    column-count: 1;
    column-gap: 0;
  }
`;

const Item = styled.div`
  display: inline-block;
  margin: 0 0 1rem;
  background: ${props => props.theme.colors.bgWhite};
  box-sizing: border-box;
  padding: 4.2rem 2.3rem 2.3rem 2.3rem;
  width: 100%;
  ul {
    list-style: none;
    margin: 0;
    li {
      font-size: 2.2rem;
      line-height: 4rem;
      font-weight: 400;
      &:before {
        content: '- ';
      }
    }
  }
`;

const Cases = styled.div`
  padding: 18rem 0;
`;

const TitleMargin = styled(Title)`
  margin: 0 0 3.6rem 0;
  text-align: center;
`;

const Projects = styled.div`
  display: flex;
  margin: 4rem 0;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    flex-direction: column;
  }
`;

const RenderImageLeft = ({ primary }) => (
  <TextImg>
    <Content>
      <Flex>
        <Col2Img>
          <img className="imgLeft" src={primary.img.url} alt={primary.img.alt} />
        </Col2Img>
        <Col2Text>
          <div dangerouslySetInnerHTML={{ __html: primary.content.html }} />
        </Col2Text>
      </Flex>
    </Content>
  </TextImg>
);

RenderImageLeft.propTypes = {
  primary: PropTypes.array.isRequired,
};

const RenderImageRight = ({ primary }) => (
  <TextImg>
    <Content>
      <Flex>
        <Col2Text>
          <div dangerouslySetInnerHTML={{ __html: primary.content.html }} />
        </Col2Text>
        <Col2Img>
          <div className="imgRight">
            <ImageBase alt={primary.img.alt} localFile={primary.img.localFile} />
          </div>
        </Col2Img>
      </Flex>
    </Content>
  </TextImg>
);

RenderImageRight.propTypes = {
  primary: PropTypes.array.isRequired,
};

const RenderText = ({ primary, bg, noBottomPadding }) => (
  <Text className={`${bg ? 'bg' : ''}${noBottomPadding ? 'noBottomPadding' : ''}`}>
    {noBottomPadding}
    <ContentText dangerouslySetInnerHTML={{ __html: primary.content.html }} />
  </Text>
);

RenderText.defaultProps = {
  bg: false,
  noBottomPadding: false,
};

RenderText.propTypes = {
  primary: PropTypes.array.isRequired,
  bg: PropTypes.bool,
  noBottomPadding: PropTypes.bool,
};

const RenderGrid = ({ items }) => (
  <CardsGrid>
    <Content>
      <Masonry>
        {items.map(item => (
          <Item dangerouslySetInnerHTML={{ __html: item.item.html }} />
        ))}
      </Masonry>
    </Content>
  </CardsGrid>
);

RenderGrid.propTypes = {
  items: PropTypes.array.isRequired,
};

const RenderCases = ({ projects, projectTeam }) => (
  <Cases>
    <Content>
      <TitleMargin>Cases</TitleMargin>
      <Projects>
        {projects.map(project => (
          <React.Fragment>
            {projectTeam ? (
              <ProjectItem key={project.project.document[0].uid} {...project.project.document[0]} />
            ) : (
              <ProjectItem key={project.node.uid} {...project.node} />
            )}
          </React.Fragment>
        ))}
      </Projects>
    </Content>
  </Cases>
);

RenderCases.propTypes = {
  projects: PropTypes.array.isRequired,
  projectTeam: PropTypes.bool.isRequired,
};

const RenderBodyElement = ({
  body: { slice_type: sliceType, primary, items },
  projects,
  noBottomPadding,
  projectTeam,
}) => {
  switch (sliceType) {
    case 'text_right_img_left':
      return <RenderImageLeft primary={primary} />;
    case 'text_left_img_right':
      return <RenderImageRight primary={primary} />;
    case 'text':
      return <RenderText primary={primary} bg={false} noBottomPadding={noBottomPadding} />;
    case 'text_with_background':
      return <RenderText primary={primary} bg />;
    case 'big_img':
      return <BigImg primary={primary} padding />;
    case 'cases':
      return <RenderCases projects={projects} projectTeam={projectTeam} />;
    case 'big_img_no_bottom_padding':
      return <BigImg primary={primary} />;
    case 'grid':
      return <RenderGrid items={items} />;
    default:
      return null;
  }
};

RenderBodyElement.defaultProps = {
  projects: null,
};

RenderBodyElement.propTypes = {
  body: PropTypes.array.isRequired,
  projects: PropTypes.array,
  noBottomPadding: PropTypes.bool.isRequired,
  projectTeam: PropTypes.bool.isRequired,
};

const Slices = ({ body, projects, noBottomPadding, projectTeam }) =>
  body &&
  body.map(b => (
    <RenderBodyElement body={b} projects={projects} noBottomPadding={noBottomPadding} projectTeam={projectTeam} />
  ));

Slices.defaultProps = {
  noBottomPadding: false,
  projectTeam: false,
  projects: null,
};

Slices.propTypes = {
  body: PropTypes.array.isRequired,
  projects: PropTypes.array,
  noBottomPadding: PropTypes.bool,
  projectTeam: PropTypes.bool,
};

export default Slices;

import React from 'react';
import styled from '@emotion/styled';
import Content from './Content';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 9rem 0;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    padding: 5rem 0;
  }
  p {
    margin: 0;
    font-size: ${props => props.theme.fontSize.textSmall};
    line-height: ${props => props.theme.lineHeight.textSmall};
    color: ${props => props.theme.colors.colorText};
    @media (max-width: ${props => props.theme.breakpoint.m}) {
      text-align: center;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
`


const Footer = () => (
  <Wrapper>
    <Content>
      <p>Argh.team OÜ, Sepapaja 6, Tallinn 15551, Estonia</p>
    </Content>
  </Wrapper>
);

export default Footer;

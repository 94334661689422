import styled from '@emotion/styled';

const Content = styled.div`
  max-width: ${props => props.theme.maxWidthContent};
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${props => props.theme.breakpoint.xl}) {
    max-width: 1100px;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    max-width: 950px;
  }
  @media (max-width: ${props => props.theme.breakpoint.ll}) {
    padding: 0 60px;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    padding: 0 16px;
  }
  ${props => props.flex && `display: flex;`}
  ${props => props.alignItems && `align-items: center;`}
`

export default Content;

/* eslint no-unused-expressions: 0 */

import React from 'react';
import PropTypes from 'prop-types';
/** @jsx jsx */
import {Global, jsx, css} from '@emotion/core';
import {ThemeProvider} from 'emotion-theming';

import SEO from './SEO';
import theme from '../../config/theme';

const Layout = ({children}) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <Global
        styles={theme => css`
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          html {
            text-rendering: optimizeLegibility;
            overflow-x: hidden;
            box-sizing: border-box;
            -ms-overflow-style: scrollbar;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: ${theme.colors.text};
            }
          }
          font-size: 10px;
          body {
            color: ${theme.colors.text};
            background-color: ${theme.colors.bg};
          }
          ::selection {
            color: ${theme.colors.bg};
            background-color: ${theme.colors.text};
          }
          a {
            color: ${theme.colors.primary};
            transition: all 0.4s ease-in-out;
            text-decoration: none;
            &:hover, &:focus {
              color: ${theme.colors.text};
            }
          }
          a:not([href]):not([tabindex]) {
            color: inherit;
            text-decoration: none;
            &:hover, &:focus {
              color: inherit;
              text-decoration: none;
            }
            &:focus {
              outline: 0;
            }
          }
        `}
      />
      <SEO />
      {children}
    </React.Fragment>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

export default Layout;

import React from 'react';
import styled from '@emotion/styled';
import Content from './Content';
import Title from './Title';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 4rem;
  background: ${props => props.theme.colors.lightgreenOpacity};
  text-align: center;
  a {
    display: block;
    font-size: ${props => props.theme.fontSize.heading};
    font-weight: 900;
    color: ${props => props.theme.colors.violet};
    line-height: ${props => props.theme.lineHeight.heading};
    @media (max-width: ${props => props.theme.breakpoint.m}) {
      font-size: 4.3rem;
      line-height: 5.6rem;
    }
  }
`

const TitleFix = styled(Title)`
  line-height: 1.1;
  background-color: ${props => props.theme.colors.pink};
  color: #fff;
  display: inline-block;
  padding: 2rem 5rem;
  margin: -4rem 0 0 0;
`

const ContentLink = styled.div`
  padding: 25rem 0;
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    padding: 15rem 0;
  }
`

const HireUsBase = () => (
  <Wrapper>
    <Content>
      <TitleFix>Hire us</TitleFix>
      <ContentLink>
        <a href="mailto:welcome@argh.team">welcome@argh.team</a>
      </ContentLink>
    </Content>
  </Wrapper>
);

export default HireUsBase;

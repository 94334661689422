import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const SpecializationLinks = ({ specializations, active }) => {
  return (
    <ul>
      {specializations.map(({specialization: {document}}) => {
        const { data, uid } = document[0];
        return (
          <li key={uid}>
            <Link className={`${active === uid && 'active'}`} to={`/${uid}`}>{data.name}</Link>
          </li>
        );
      })}
    </ul>
  );
};

SpecializationLinks.propTypes = {
  specializations: PropTypes.array.isRequired,
  active: PropTypes.string,
};

SpecializationLinks.defaultProps = {
  active: '',
};

export default SpecializationLinks;

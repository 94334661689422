import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Content from '../components/Content';
import ImageBase from './ImageBase';

const ImgBlock = styled.div`
  padding: 1rem 0 0 0;
  background: ${props => props.theme.colors.lightBlue};
`;

const Wrapper = styled.div`
  padding: ${props => props.padded && '0 0 1rem 0'};
`

const BigImg = ({ primary, padding }) => (
  <ImgBlock>
    <Content>
      <Wrapper padded={padding}>
        <ImageBase alt={primary.img.alt} localFile={primary.img.localFile} />
      </Wrapper>
    </Content>
  </ImgBlock>
);

BigImg.defaultProps = {
  padding: false,
};

BigImg.propTypes = {
  primary: PropTypes.array.isRequired,
  padding: PropTypes.bool,
};

export default BigImg;

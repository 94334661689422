import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 40%;
  margin-right: 5%;
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    width: 100%;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  border: 0.3rem solid ${props => props.theme.colors.lightgreen};
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding: 6rem;

  position: relative;
  transition: 0.2s;
  &:hover {
    background-color: rgba(0, 249, 176, 0.2);
  }
  img {
    max-width: 28rem;
    max-height: 28rem;
    border-radius: 100%;
  }
  &:last-child {
    margin: 0;
  }
`;

const LinkProject = styled(Link)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h4`
  font-size: ${props => props.theme.fontSize.heading2};
  line-height: 1;
  font-weight: 900;
  color: ${props => props.theme.colors.colorText};
  margin: 0;
`;

const Description = styled.p`
  font-size: ${props => props.theme.fontSize.textBody};
  line-height: ${props => props.theme.lineHeight.textBody};
  font-weight: 400;
  color: ${props => props.theme.colors.colorText};
`;

const Heading = styled.div`
  //display: flex;
  min-width: 50%;
  margin: 2rem 0 2rem 0;
`;

const Meta = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoint.xl}) {
    flex-direction: row;
  }
  @media (max-width: ${props => props.theme.breakpoint.mx}) {
    flex-direction: column;
  }
`;

const MetaItem = styled.div`
  margin: 1rem 0;
  flex: 1 1 auto;
  box-sizing: border-box;
  text-align: left;
  padding-right: 4%;

  h5 {
    margin: 0;
    font-size: ${props => props.theme.fontSize.textSmall};
    line-height: ${props => props.theme.lineHeight.textSmall};
    font-weight: 600;
    color: ${props => props.theme.colors.colorTextGrey};
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-size: ${props => props.theme.fontSize.textSmall};
      line-height: ${props => props.theme.lineHeight.textSmall};
      font-weight: 400;
      padding: 0;
      margin: 0;
      display: inline-block;
      color: ${props => props.theme.colors.colorTextLightGrey};
    }
  }
`;

const ProjectItem = data => {
  const {
    data: { title, description, previewi_img, tech_stack, project_type },
    uid,
  } = data;

  return (
    <Wrapper>
      <LinkProject to={uid} />
      <img src={previewi_img.url} alt={`${title.text}-img`} />
      <Content>
        <Heading>
          <Title>{title.text}</Title>
          <Description>{description.text}</Description>
        </Heading>
        <Meta>
          <MetaItem>
            <h5>Project type</h5>
            <ul>
              {project_type.map(
                ({ specialization }) => specialization && <li>{specialization.document[0].data.name},&nbsp;</li>
              )}
            </ul>
          </MetaItem>
          <MetaItem>
            <h5>Technologies used:</h5>
            <ul>
              {tech_stack.map(({ technology }) => technology && <li>{technology.document[0].data.name},&nbsp;</li>)}
            </ul>
          </MetaItem>
        </Meta>
      </Content>
    </Wrapper>
  );
};

export default ProjectItem;

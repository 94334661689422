import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ImageBase = ({ className, alt, localFile }) => {
  const sources = [
    localFile.childImageSharp.sizes,
    {
      ...localFile.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return <Img className={className} alt={alt} fluid={sources} />;
}

export default ImageBase;

ImageBase.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  localFile: PropTypes.object.isRequired,
}

ImageBase.defaultProps = {
  className: null,
}

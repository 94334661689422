import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Content from './Content';
import SpecializationLinks from './SpecializationLinks';

const Wrapper = styled.header`
  width: 100%;
  text-align: left;
  padding-top: 1.5em;
  ul {
    padding: 0;
    list-style: none;
    margin: 0 0 0 auto;
    li {
      display: inline-block;
      margin: 0;
      margin: 0 1rem 0 0;
      line-height: 1.2em;
      a {
        color: ${props => props.theme.colors.colorText};
        margin: 0;
        line-height: 1;
        font-weight: 400;
        font-size: ${props => props.theme.fontSize.textSmall};
        display: inline-block;
        position: relative;
        transition: 0.5s;
        &.active {
          &:before {
            width: 100%;
          }
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 2;
          bottom: -4px;
          transition: 0.5s;
          width: 0%;
          height: 2px;
          background: ${props => props.theme.colors.pink};
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -4px;
          width: 100%;
          height: 2px;
          background: ${props => props.theme.colors.lightgreen};
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
`;

const Logo = styled(Link)`
  color: ${props => props.theme.colors.colorText};
  display: inline-block;
  font-weight: 900;
  font-size: 2rem;
  line-height: 4.3rem;
`;

const Header = ({ specializations, menu, active }) => (
  <Wrapper>
    <Content flex={menu} alignItems={menu}>
      <Logo to="/">Argh.team</Logo>
      {menu && <SpecializationLinks active={active} specializations={specializations} />}
    </Content>
  </Wrapper>
);

Header.propTypes = {
  specializations: PropTypes.array,
  menu: PropTypes.bool,
  active: PropTypes.string,
};

Header.defaultProps = {
  specializations: [],
  menu: false,
  active: '',
};

export default Header;

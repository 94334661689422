import styled from '@emotion/styled';

const Title = styled.h2`
  font-size: ${props => props.theme.fontSize.heading};
  font-weight: 900;
  color: ${props => props.theme.colors.pink};
  line-height: ${props => props.theme.lineHeight.heading};
  margin: 5rem 0;
`;

export default Title;

const theme = {
  colors: {
    text: '#000000',
    pink: '#FF5C92',
    lightgreen: '#00F9B0',
    lightBlue: '#B2FFEB',
    bgWhite: '#fff',
    lightgreenOpacity: 'rgba(0, 249, 176, 0.23)',
    colorText: '#000000',
    colorTextGrey: '#404040',
    colorTextLightGrey: '#666666',
    violet: '#40166B',
  },
  fontFamily: {
    body: `'Muli'`,
  },
  maxWidthContent: '1140px',
  breakpoint: {
    xs: '400px',
    s: '600px',
    m: '700px',
    mx: '900px',
    sl: '1000px',
    ll: '1100px',
    l: '1200px',
    lm: '1300px',
    xl: '1450px',
    xxl: '1600px',
  },
  fontSize: {
    headingLarge: '8rem',
    heading: '6.5rem',
    textLead: '4.1rem',
    heading2: '3.6rem',
    heading3: '3.2rem',
    textBody: '2.2rem',
    textSmall: '1.7rem',
  },
  lineHeight: {
    headingLarge: '9.4rem',
    heading: '9.4rem',
    heading2: '4.3rem',
    textBody: '4rem',
    textSmall: '2.4rem',
  },
};

export default theme;
